import { configureStore } from '@reduxjs/toolkit'
import {profileReducer} from "./Model/Profile";
import {useDispatch, useSelector} from "react-redux";

export const store = configureStore({
    reducer: {
        profile: profileReducer
    }
})

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppStore = typeof store

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<AppState>()
