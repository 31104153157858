import {createTheme} from "@mui/material";

const isDark: boolean = window.Telegram.WebApp.colorScheme === "dark";
export const theme = createTheme({
    palette: {
        mode: window.Telegram.WebApp.colorScheme,
        background: {
            default: isDark ? "#12140e" : "#f9faef",
            paper: isDark ? "#383a32" : "#dadbd0",
        },
        primary: {
            main: isDark ? "#b1d18a" : "#4c662b",
            contrastText: isDark ? "#1f3701" : "#ffffff",
        },
        secondary: {
            main: isDark ? "#bfcbad" : "#586249",
            contrastText: isDark ? "#2a331e" : "#ffffff",
        },
        error: {
            main: isDark ? "#ffb4ab" : "#ba1a1a",
        },
    },
});