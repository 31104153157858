import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppState} from "../store";

export default interface Profile {
    id: string,
    name: string,
    status: string,
}

interface ProfileSlice {
    authorized?: Profile,
}

const initialState: ProfileSlice = {};

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        authProfile: (state, action: PayloadAction<Profile>) => {
            state.authorized = action.payload
        },
    }
})

export const { authProfile } = profileSlice.actions

export const getCurrentProfile = (state: AppState) => state.profile;

export const profileReducer = profileSlice.reducer;