import {Avatar, Box, Button, Paper, Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {deepOrange} from "@mui/material/colors";
import {useAppSelector} from "../../Data/store";

export default function Home() {
    const profile = useAppSelector(state => state.profile.authorized)

    return(
        <Grid container spacing={2} sx={{mt: 2}}>
            <Grid size={6}>
                <Paper elevation={1} sx={{ p: 2, display: "flex", height: "100%", borderRadius: 2 }}>
                    <Avatar sx={{
                        backgroundColor: deepOrange[500],
                        width: 48,
                        height: 48,
                    }}>JS</Avatar>
                    <Box sx={{ml: 2}}>
                        <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>John Smith</Typography>
                    </Box>
                </Paper>
            </Grid>
            <Grid size={6}>
                <Paper elevation={1} sx={{p: 2, display: "flex", height: "100%", borderRadius: 2}}>
                    <Box sx={{fontSize: 40, lineHeight: 1}}>
                        👶
                    </Box>
                    <Box sx={{ml: 2}}>
                        <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>Rank</Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>Newbie</Typography>
                    </Box>
                </Paper>
            </Grid>
            <Grid size={12}>
                <Button variant="contained" sx={{width: "100%", borderRadius: 2}}>Quick game</Button>
            </Grid>
            <Grid size={12}>
                <Button variant="contained" sx={{width: "100%", borderRadius: 2}}>Invite friends</Button>
            </Grid>
            <Grid size={12}>
                <Button variant="contained" sx={{width: "100%", borderRadius: 2}}>About game...</Button>
            </Grid>
        </Grid>
    );
}