import './index.scss';
import React, {PropsWithChildren} from "react";

export default function DevWrap (props: PropsWithChildren) {
    if (process.env.NODE_ENV === 'development') {
        return (
            <div className="dev-wrap">
                {props.children}
            </div>
        );
    }
    return (
        <>
            {props.children}
        </>
    );
}