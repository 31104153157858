import React from "react";
import { Container } from "@mui/material";
import Home from "./Page/Home";

export default function App() {
    return (
        <Container maxWidth="xs" sx={{width: 400}}>
            <Home />
        </Container>
    );
}