import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import './Dev/MockTelegramWebApp/MockTelegramService'
import {CssBaseline, ThemeProvider} from "@mui/material";
import {theme} from "./theme";
import DevWrap from './Dev/DevWrap';
import { Provider } from 'react-redux'
import {store} from "./Data/store";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

console.log(theme.breakpoints)
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <DevWrap>
                    <CssBaseline />
                    <App />
                </DevWrap>
            </ThemeProvider>
        </Provider>
    </React.StrictMode>
);